@tailwind base;
@tailwind components;
@tailwind utilities;


@media () {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body { 
  background-color: rgb(255, 255, 255);
  color:black;
  font-family: 'Montserrat';
  font-size: 14px;
}

.custom  p {
  margin-bottom: 1em;
}

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

@keyframes fadeAnimOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0
  }
}

.premium-fade-in {
  animation: fadeAnim 1s ease-in;
}

.premium-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
